import React, {useEffect, useState, useContext} from 'react';
import axios from '../utils/service';
import AuthContext from '../context/AuthContext';
import {Link, useNavigate} from 'react-router-dom';
import Spinner from "./Spinner";


const AdminPanelLessonsEditor = () => {
    const {user} = useContext(AuthContext);
    const [lessons, setLessons] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedLessons, setSelectedLessons] = useState([]);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [bulkCategory, setBulkCategory] = useState("");

    const navigate = useNavigate();
    useEffect(() => {
        const fetchCategories = async() => {
            if (user && user.role === 'admin') {
                const {data} = await axios.get(`/admin/categories`, {
                    headers: {Authorization: `Bearer ${user.token}`},
                });
                setCategories(data);
            }
        };
        const fetchUsers = async () => {
            if (user && user.role === 'admin') {
                const {data} = await axios.get(`/admin/lessons`, {
                    headers: {Authorization: `Bearer ${user.token}`},
                });
                setLessons(data);
            }
        };
        fetchUsers().catch(console.error);
        fetchCategories().catch(console.error);
    }, [user]);

    const deleteLesson = async (lessonId) => {
        const answer = window.confirm("Are you sure you want to delete?");
        if(answer) {
            const resp = await axios.delete(`/admin/lessons/${lessonId}`,{
                headers: {Authorization: `Bearer ${user.token}`}
            });
            setLessons(lessons.filter(l => l._id !== lessonId));
            console.log(resp);
        }
    };

    const saveLessonCategory = async (lesson, categoryId) => {
        const { data } = await axios.put(`/admin/lesson/${lesson._id}`, {
                categoryId: categoryId,
                _id: lesson._id
            },
            {
                headers: {Authorization: `Bearer ${user.token}`},
            });
        lesson.category = data.category;
    }

    const toggleSelectedLessons = async (selectedLesson, target) => {
        selectedLesson.target = target;
        if(selectedLessons.indexOf(selectedLesson) !== -1) {
            setSelectedLessons(selectedLessons.filter(l => l._id !== selectedLesson._id));
        } else {
            setSelectedLessons([...selectedLessons, selectedLesson]);
        }
    };

    const handleBulkSave = async (e) => {
        e.preventDefault();
        const categoryId = bulkCategory;
        for(const lesson of selectedLessons) {
            await saveLessonCategory(lesson, categoryId);
            if(lesson.target) {
                lesson.target.checked = !lesson.target.checked;
            }
        }
        setBulkCategory("");
        setSelectedLessons([]);
        setShowCategoryModal(false);
    }
    const handleBulkOpen = () => setShowCategoryModal(true);
    const handleBulkClose = () => {
        setBulkCategory("");
        setShowCategoryModal(false);
    }


    const styles = {
        container: {
            padding: '20px',
        },
        selectCategory: {
            maxWidth: '100%',
            width: '300px',
            padding: '8px',
            margin: '10px 0'
        },
        editButton: {
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            padding: '5px 10px',
            cursor: 'pointer',
            borderRadius: '3px',
            marginRight: '5px',
        },
        deleteButton: {
            backgroundColor: '#ff1f33',
            color: '#fff',
            border: 'none',
            padding: '5px 10px',
            cursor: 'pointer',
            borderRadius: '3px',
            marginRight: '5px',
        },
        cancelButton: {
            backgroundColor: '#ff1f33',
            marginBottom: '20px',
            padding: '10px 15px',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '3px',
            marginRight: '12px',
        },
        addButton: {
            marginBottom: '20px',
            padding: '10px 15px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '3px',
            marginRight: '12px',
        },
        bulkEditButton: {
            marginBottom: '20px',
            padding: '10px 15px',
            backgroundColor: '#109b11',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '3px',
            marginRight: '12px',
        }, table: {
            width: '100%', borderCollapse: 'collapse', marginTop: '20px',
        }, th: {
            borderBottom: '2px solid #ddd', padding: '10px', textAlign: 'left', backgroundColor: '#333', color: '#fff',
        }, td: {
            borderBottom: '1px solid #ddd', padding: '10px',
        },
        modal: {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '20px',
                zIndex: '1000000',
                overflowY: 'scroll',
                width: '800px',
                height: '600px'
            },
        },
    };


    return (
        <div style={styles.container}>
            <h1>Admin Panel</h1>
            <h2>Lessons</h2>
            {!lessons ? (
                <Spinner/>
            ) : (
                <>
                    <button onClick={() => navigate('/admin/lessons/add')} style={styles.addButton}>Add Lesson</button>
                    {selectedLessons.length > 1 && (<button onClick={handleBulkOpen} style={styles.bulkEditButton}>Bulk Edit</button>)}
                    {showCategoryModal && (<div>
                            <select style={{...styles.selectCategory, fontSize: '14px', marginRight: '12px' }} onChange={(e) => setBulkCategory(e.target.value)}>
                                <option value="">Select</option>
                                {categories.map(cat => (
                                    <option key={cat._id} value={cat._id}>{cat.title}</option>
                                ))}
                            </select>
                            <button style={styles.cancelButton} onClick={handleBulkClose}>Cancel</button>
                            <button style={styles.addButton} onClick={handleBulkSave}>Save</button>
                    </div>)}

                    <table style={styles.table}>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {lessons.map((lesson) => (<tr key={lesson._id}>
                            <td style={styles.td}><label><input type="checkbox" onClick={(e) => toggleSelectedLessons(lesson, e.target)} />{lesson._id.slice(-8)}</label></td>
                            <td style={styles.td}>{lesson.title}</td>
                            <td style={styles.td}>{lesson.category?.title}</td>
                            <td style={styles.td}>
                                <button style={styles.editButton} onClick={() => navigate(`/admin/lessons/${lesson._id}`)}>Edit</button>
                                <button style={styles.deleteButton} onClick={() => deleteLesson(lesson._id)}>delete</button>
                            </td>
                        </tr>))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};
export default AdminPanelLessonsEditor;
