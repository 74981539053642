import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from '../utils/service';
import Spinner from "./Spinner";
import dashStyle from './Dashboard.module.css';
import AuthContext from '../context/AuthContext';

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const [activeCategory, setActiveCategory] = useState(null); // Track active category for accordion
  const [columns, setColumns] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    if(user.role === 'subscriber') {
      const fetchCategories = async () => {
        const response = await axios.get(`/lessons/categories?style=dashboard`, config);
        const categoryEntries = Object.entries(response.data);
        const columns = [[], [], []];
        categoryEntries.forEach((entry, index) => {
          columns[index % 3].push(entry); // Distribute categories into 3 columns
        });
        setColumns(columns);
      };
      fetchCategories().catch(console.error);
    }
  }, [user]);

  const toggleCategory = (categoryName) => {
    setActiveCategory(activeCategory === categoryName ? null : categoryName); // Toggle category
  };
  if(user) {
    if(user.role === 'subscriber') {
      return (
          <div style={styles.container}>
            {user.role === "subscriber" && (
                <>
                  <h1>Categories</h1>
                  {columns && (
                      <div className={dashStyle.colContainer} style={styles.colContainer}>
                        {/* Column 1 */}
                        <div className={dashStyle.col} style={styles.col}>
                          {columns[0].map(([category, lessons], index) => (
                              <div key={index} className={dashStyle.accordionItem} style={styles.accordionItem}>
                                <div
                                    className={dashStyle.accordionHeader}
                                    style={styles.accordionHeader}
                                    onClick={() => toggleCategory(category)}
                                >
                                  {category}
                                  <span
                                      className={dashStyle.arrow}
                                      style={{
                                        ...styles.arrow,
                                        transform: activeCategory === category ? 'rotate(180deg)' : 'rotate(0deg)'
                                      }}
                                  >
                        ▼
                      </span>
                                </div>
                                {activeCategory === category && (
                                    <div className={dashStyle.accordionContent} style={styles.accordionContent}>
                                      <ul style={styles.lessonList}>
                                        {lessons.map((lesson, lessonIndex) => (
                                            <li key={lessonIndex} style={styles.lessonItem}>
                                              <Link
                                                  to={`/lessons/${lesson.title}`}
                                                  style={styles.lessonLink}
                                              >
                                                {lesson.title}
                                              </Link>
                                            </li>
                                        ))}
                                      </ul>
                                    </div>
                                )}
                              </div>
                          ))}
                        </div>

                        {/* Column 2 */}
                        <div className={dashStyle.col} style={styles.col}>
                          {columns[1].map(([category, lessons], index) => (
                              <div key={index} className={dashStyle.accordionItem} style={styles.accordionItem}>
                                <div
                                    className={dashStyle.accordionHeader}
                                    style={styles.accordionHeader}
                                    onClick={() => toggleCategory(category)}
                                >
                                  {category}
                                  <span
                                      className={dashStyle.arrow}
                                      style={{
                                        ...styles.arrow,
                                        transform: activeCategory === category ? 'rotate(180deg)' : 'rotate(0deg)'
                                      }}
                                  >
                        ▼
                      </span>
                                </div>
                                {activeCategory === category && (
                                    <div className={dashStyle.accordionContent} style={styles.accordionContent}>
                                      <ul style={styles.lessonList}>
                                        {lessons.map((lesson, lessonIndex) => (
                                            <li key={lessonIndex} style={styles.lessonItem}>
                                              <Link
                                                  to={`/lessons/${lesson.title}`}
                                                  style={styles.lessonLink}
                                              >
                                                {lesson.title}
                                              </Link>
                                            </li>
                                        ))}
                                      </ul>
                                    </div>
                                )}
                              </div>
                          ))}
                        </div>

                        {/* Column 3 */}
                        <div className={dashStyle.col} style={styles.col}>
                          {columns[2].map(([category, lessons], index) => (
                              <div key={index} className={dashStyle.accordionItem} style={styles.accordionItem}>
                                <div
                                    className={dashStyle.accordionHeader}
                                    style={styles.accordionHeader}
                                    onClick={() => toggleCategory(category)}
                                >
                                  {category}
                                  <span
                                      className={dashStyle.arrow}
                                      style={{
                                        ...styles.arrow,
                                        transform: activeCategory === category ? 'rotate(180deg)' : 'rotate(0deg)'
                                      }}
                                  >
                        ▼
                      </span>
                                </div>
                                {activeCategory === category && (
                                    <div className={dashStyle.accordionContent} style={styles.accordionContent}>
                                      <ul style={styles.lessonList}>
                                        {lessons.map((lesson, lessonIndex) => (
                                            <li key={lessonIndex} style={styles.lessonItem}>
                                              <Link
                                                  to={`/lessons/${lesson.title}`}
                                                  style={styles.lessonLink}
                                              >
                                                {lesson.title}
                                              </Link>
                                            </li>
                                        ))}
                                      </ul>
                                    </div>
                                )}
                              </div>
                          ))}
                        </div>
                      </div>
                  )}
                  {!columns && (
                      <Spinner/>
                  )}
                </>
            )}
          </div>
      );
    } else if (user.role === 'user') {
      return (
          <div style={styles.container}>
            <h1>Subscribe to Get Access</h1>
            <p>
              If you wish to get access to the content, please <Link to={"/subscribe"}>subscribe</Link>.
            </p>
          </div>
      );
    } else {
      return (
          <div style={styles.container}>
            <h1>Welcome Admin</h1>
            <p>See <a href="/lessons">Lessons</a> page</p>
            <p>or edit <a href="/admin/lessons">Lessons</a></p>
          </div>
      );
    }
  } else {
    return (
        <div style={styles.container}>
          <p>You're not authorized to see this content.</p>
          <p>Please, log in.</p>
        </div>
    );
  }
};

const styles = {
  container: {
    padding: '20px',
  },
  colContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  col: {
    flex: 1,
    padding: '10px',
  },
  accordionItem: {
    marginBottom: '10px',
  },
  accordionHeader: {
    padding: '10px',
    backgroundColor: '#f0f0f0',
    cursor: 'pointer',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between', // Ensures the arrow is on the right
    alignItems: 'center', // Aligns the arrow vertically in the center
  },
  accordionContent: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
  },
  arrow: {
    transition: 'transform 0.3s ease', // Smooth transition for rotating the arrow
    marginLeft: '10px',
  },
  lessonList: {
    listStyleType: 'disc', // Bulleted list
    paddingLeft: '20px', // Standard padding for bullet list
  },
  lessonItem: {
    marginBottom: '8px',
  },
  lessonLink: {
    color: '#000', // Black color for the lessons
    textDecoration: 'underline', // Underline the lessons
    fontWeight: 400, // Reduced font weight
  },
};

export default Dashboard;
