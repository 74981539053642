import React, {useEffect, useState, useContext} from 'react';
import axios from '../utils/service';
import AuthContext from '../context/AuthContext';
import {useRef} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {useNavigate, useParams} from "react-router-dom";

const AdminPanelAddLesson = () => {
    const {user} = useContext(AuthContext);
    const [title, setTitle] = useState('');
    const [categories, setCategories] = useState();
    const [categoryId, setCategoryId] = useState();
    const [orderInCategory, setOrderInCategory] = useState(0);
    const {lessonId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
       setTitle('');
        const fetchCategory = async () => {
            if (user && user.role === 'admin') {
                const {data} = await axios.get(`/admin/categories/`, {
                    headers: {Authorization: `Bearer ${user.token}`},
                });
                setCategories([...data]);
                return [...data];
            }
        };
        fetchCategory().then(r => console.log(r));
    }, [user, lessonId]);

    const editorRef = useRef(null);
    const handleTitleChange = (event) => {
        // Update the state with the current input value
        setTitle(event.target.value);
    };

    const handleOrderInCategoryChange = (event) => {
        setOrderInCategory(event.target.value);
    }

    const saveChanges = async () => {
        const body = editorRef.current.getContent();
        console.log(body);
        if(title && body && categoryId) {
            await axios.post(`/admin/lesson/`, {
                    title: title,
                    body: body,
                    category: categoryId
                },
                {
                    headers: {Authorization: `Bearer ${user.token}`},
                }).then((r) => {
                alert('Saved successfully');
                navigate('/admin/lessons/');
            })
        } else {
            alert('Fill in missing fields.');
        }
    };


    const styles = {
        container: {
            padding: '20px',
        },
        addButton: {
            padding: '10px 20px',
            margin: '10px',
            backgroundColor: '#35c93d',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '3px',
        },
        input: {
            maxWidth: '100%',
            width: '100%',
            padding: '8px',
            margin: '10px 0'
        },
        inputTitle: {
            height: '32px',
            lineHeight: '32px',
            width: '100%',
            fontSize: '24px',
            border: '1px solid #eee'
        },
        cancelButton: {
            backgroundColor: '#c9353d',
            color: '#fff',
            border: 'none',
            padding: '10px 20px',
            margin: '10px',
            cursor: 'pointer',
            borderRadius: '3px',
        },
        containerStyle: {
            display: 'flex',
            justifyContent: 'space-between', // Pushes buttons to opposite ends
            padding: '10px',
        },
        modal: {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                padding: '20px',
                zIndex: '1000000',
                overflowY: 'scroll',
                width: '800px',
                height: '600px'
            },
        },
    };

    return (
        <div style={styles.container}>
                    <div style={{marginBottom: '10px'}}>
                        <label>
                            Title:
                            <input style={styles.inputTitle} type={"text"} value={title} onChange={handleTitleChange}/>
                        </label>
                    </div>
                    <div>
                        <p>
                            <label htmlFor="orderInCategory">Order: </label>
                            <input id="orderInCategory" type={"number"} value={orderInCategory}
                                   onChange={handleOrderInCategoryChange}/>
                        </p>
                    </div>
                    <div style={{margin: '16px 0', fontSize: '16px'}}>
                        <label>
                            Category:
                            {categories && (
                                <select style={{...styles.input, fontSize: '14px'}} name="role" value={categoryId}
                                        onChange={(e) => setCategoryId(e.target.value)} required>
                                    <option value="">-- select category --</option>
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.title}
                                        </option>
                                    ))}
                                </select>)
                            }
                        </label>
                    </div>
                    <Editor
                        apiKey='cjn0zvvqhlfqcjphulqj1fwxk6bl037z401hlcjs1q17pcjt'
                        onInit={(_evt, editor) => editorRef.current = editor}
                        initialValue=""
                        init={{
                            height: 600,
                            menubar: true,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | image | help',
                            // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            images_upload_url: null, // Disable server-side image handling
                            file_picker_types: 'image',
                            images_dataimg_filter: function (img) {
                                return img.hasAttribute('src');
                            },
                            file_picker_callback: function (callback, value, meta) {
                                if (meta.filetype === 'image') {
                                    const input = document.createElement('input');
                                    input.setAttribute('type', 'file');
                                    input.setAttribute('accept', 'image/*');

                                    input.onchange = function () {
                                        const file = input.files[0];
                                        const reader = new FileReader();

                                        reader.onload = function (e) {
                                            // Convert the image to Base64
                                            callback(e.target.result, {alt: file.name});
                                        };
                                        reader.readAsDataURL(file);
                                    };

                                    input.click();
                                }
                            }
                        }}
                    />
                    <p style={styles.containerStyle}>
                        <button style={styles.cancelButton} onClick={() => navigate(-1)}>Discard Changes</button>
                        <button style={styles.addButton} onClick={saveChanges}>Save Changes</button>
                    </p>
        </div>
    );
};

export default AdminPanelAddLesson;
